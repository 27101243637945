<template>
  <div class="c-checkout-buy-coupon check-coupon" v-if="showBuyCoupon">
    <div
      class="buy-coupon-wrap"
      :class="{ disabled: buyCouponDisabled }"
      v-enterkey
      @click="preHandleSelected"
      tabindex="0"
      role="button"
    >
      <s-radio
        :modelValue="buyCouponChecked"
        :checked="buyCouponChecked"
        :disabled="buyCouponDisabled"
        name="buycoupon"
        size="radio20"
        theme="icon"
        gap="0px"
        :label="true"
        @click.stop.prevent="preHandleSelected"
      >
      </s-radio>

      <div class="buy-coupon-content">
        <div class="buy-coupon-title">{{ language.SHEIN_KEY_PC_17350 }}</div>
        <p class="title" v-html="buyCouponTitle"></p>
        <p class="desc" v-if="couponDetails && couponDetails.discountPrice">
          {{
            htmlDecode({
              text: template(
                couponDetails.discountPrice.amountWithSymbol,
                language.SHEIN_KEY_PC_17366
              ),
            })
          }}
        </p>
        <p class="view-coupons">
          <span @click.stop="viewCoupons(true)">
            {{ template(couponsNum, language.SHEIN_KEY_PC_17787) }}
            <Icon
              name="sui_icon_more_right_12px_1"
              size="12px"
            />
          </span>
        </p>
        <p
          class="disabled-tips"
          v-if="buyCouponDisabled"
          v-html="language.SHEIN_KEY_PC_17353"
        ></p>
      </div>
    </div>
    <template v-if="buyCouponChecked && buyCouponTip">
      <p
        class="buycoupon-tips auto"
        v-if="
          buyCouponApplyType === 1 &&
          couponDetails &&
          couponDetails.discountPrice
        "
      >
        {{
          language.SHEIN_KEY_PC_17351.replace(
            '{0}',
            couponDetails.discountPrice.amountWithSymbol
          )
        }}
      </p>
      <p
        class="buycoupon-tips"
        v-else-if="
          buyCouponApplyType === 0 && coupon.val != couponDetails.couponCode
        "
      >
        {{ language.SHEIN_KEY_PC_17352 }}
      </p>
    </template>
    <checkout-buy-coupon-drawer
      :buy-coupon-checked="buyCouponChecked"
      @purchase="clickSelected"
    ></checkout-buy-coupon-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import analysisCoupon from '../../analysis/coupon'
import CheckoutBuyCouponDrawer from './CheckoutBuyCouponDrawer'
import couponMixin from '../../mixins/couponMixin'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template as _commonTemplate, htmlDecode } from '@shein/common-function'
import { isToLowerCase } from '../../config/tools'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  components: {
    CheckoutBuyCouponDrawer,
    Icon,
  },
  mixins: [couponMixin],
  computed: {
    ...mapState(['buyCouponActivity', 'buyCouponTip', 'buyCouponApplyType', 'updatingPrice']),
    buyCouponChecked: {
      get() {
        return this.$store.state.buyCouponChecked
      },
      set(val) {
        this.assignState({ buyCouponChecked: val })
      },
    },
    showBuyCoupon() {
      return this.buyCouponList.length > 0
    },
    buyCouponList() {
      return this.buyCouponActivity?.rules?.coupon || []
    },
    couponsNum() {
      return this.buyCouponList.reduce((num, item) => {
        return num + (Number(item.couponTimes) || 0)
      }, 0)
    },
    buyCouponTitle() {
      const couponPackagePrice =
        this.buyCouponActivity.rules.limitPrice.amountWithSymbol
      return _commonTemplate(
        couponPackagePrice,
        this.couponsNum,
        htmlDecode({ text: this.language.SHEIN_KEY_PC_17785, isHtml: true })
      )
    },
    buyCouponDisabled() {
      return this.buyCouponActivity.isDisabled == 1
    },
  },
  watch: {
    showBuyCoupon(n, o) {
      if (n && n != o) {
        daEventCenter.triggerNotice({ daId: '1-11-1-18' })
      }
    },
    buyCouponChecked(n, o) {
      if (n) {
        this.assignState({ buyCouponTip: true })
        daEventCenter.triggerNotice({ daId: '1-11-1-23' })
      } else {
        analysisCoupon.click_moredealoff()
      }
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    template: _commonTemplate,
    htmlDecode,
    preHandleSelected() {
      // 计算价格请求过程中 禁止勾选买券，例如选择cod在请求过程中点击买券 页面展示错误
      if (this.updatingPrice) return;
      
      if (this.buyCouponChecked) {
        this.clickSelected()
      } else {
        this.assignState({ showBuyCouponList: true })
      }
    },
    clickSelected() {
      if (this.buyCouponDisabled) return
      this.assignState({ buyCouponChecked: !this.buyCouponChecked })
      const { applyCouponsSuccess = [], applyCoupons } = this.coupon

      if (this.buyCouponChecked) {
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            is_buy_coupon: 1,
          },
        })
        if (!applyCouponsSuccess.length) {
          // 没有使用券，给用户使用买券最优
          const couponCode = this.couponDetails.couponCode || ''
          this.newApplyCoupon(
            {
              item: {
                coupon: couponCode,
              },
            },
            { type: 'costShow', isBuyCoupon: true }
          )
        } else {
          // 有使用买券 更新券列表
          this.getUpdateCoupon(
            {
              latest_coupon: applyCoupons[applyCoupons.length - 1] || '',
            },
            { isBuyCoupon: true }
          )
        }
      } else {
        let notBuyCoupon = []
        if (
          this.buyCouponActivity?.rules?.coupon?.length &&
          applyCouponsSuccess.length
        ) {
          // 使用成功的券里面包含买券， 重新请求券列表和价格 筛除在更新券列表和价钱
          let filterBuyCoupon = []
          applyCoupons.forEach((i) => {
            this.buyCouponActivity.rules.coupon.forEach((item) => {
              if (isToLowerCase(i, item.couponCode)) {
                filterBuyCoupon.push(i)
              }
            })
          })
          notBuyCoupon = applyCoupons.filter(
            (item) => !filterBuyCoupon.includes(item)
          )
        }
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            is_buy_coupon: 0,
            coupon: notBuyCoupon.length ? notBuyCoupon.join() : '',
          },
        })
        this.getUpdateCoupon(
          {
            latest_coupon: notBuyCoupon[notBuyCoupon.length - 1] || '',
          },
          { isBuyCoupon: true }
        )
      }
    },
    viewCoupons() {
      this.assignState({ showBuyCouponList: true })
      daEventCenter.triggerNotice({ daId: '1-11-1-19' })
    },
  },
}
</script>

<style lang="less" scoped>
.buy-coupon-title {
  .title {
    font {
      color: @sui_color_discount_dark;
    }
  }
}
.buy-coupon-wrap {
  cursor: pointer;
}
.check-coupon{
		margin: 5px 0 30px 0;
		.apply-box{
			margin-top: 3px;
			.c-input{
				width: calc(~"65% - 10px");
				input:disabled{
					color: #999;

					/* ipad下safari的input disabled字体不明显兼容 */
					opacity: 1;
					-webkit-text-fill-color: #999;
					-moz-text-fill-color: #999;
					-ms-text-fill-color: #999;
					-o-text-fill-color: #999;
				}
        input{
          &::-webkit-input-placeholder,
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #bbbbbb;
          }
        }
				input.input-error-bg{
					border-color: @sui_color_unusual;
					background-color: @sui_color_unusual_bg;
				}
			}
			>a{
				width: 35%;
				display: block;
			}
		}
		// &.gift-card-apply{
			.apply-box{
				.card-num{
					width: calc(~"47% - 10px");
				}
				.password{
					width: 17%;
					.margin-l(1%);
					margin-top: 0;
				}
				>a{
					width: 35%;
					display: block;
				}
			// }
			.la-ball-pulse{
				>div{
					background-color:#fff;
				}
			}
		}
		.error-tip{
			margin-top: 5px;
			color: @sui_color_unusual;
		}
		.warn-tip{
			margin-top: 5px;
		}
		.success-tip{
			margin-top: 5px;
			// margin-bottom: 5px;
			color: @sui_color_success;
		}
		.point-link{
			margin-top: 5px;
			color: #767676;
			display: inline-block;
			text-decoration: none;
			a {
				cursor: pointer;
				color: @sui_color_link;
			}
		}
		.first-order-off{
			margin-top: 10px;
		}
		.flash-red #message {
			color: red;
    }
    .red-point-tips {
      a{ position: relative ; }
      a:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background: #FF411C;
        border-radius: 50%;
        top: 0;
        .right(-8px);
      }
    }
		.pick-gift{
			background: #FFF7F0;
			border-radius: 2px;
			margin-top: 15px;
			padding: 12px 10px 10px;
			.flexbox();
			.align-center();
			.space-between();
			.pick-gift-left{
				.pick-gift-title{
					font-size: 12px;
					font-weight: bold;
					color: #222222;
					line-height: 14px;
					text-transform: capitalize;
					padding-bottom: 5px;
				}
				.pick-gift-desc{
					font-size: 12px;
					color: #222;
					font-weight: normal;
				}
			}
			.pick-gift-right{
				span{
					font-size: 12px;
					color: #222;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
.c-checkout-buy-coupon {
	.buy-coupon-title{
		font-size: 14px;
		color: #222;
		font-weight: bold;
		margin-bottom: 10px;
		text-transform: capitalize;
		i {
			position: relative;
			color: #999;
			.buy-coupon-tip{
				padding: 10px;
				width: 290px;
				position: absolute;
				background: #fff;
				box-shadow: 0 0 10px #ccc;
				z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
				.left(50%);
				.transform-x(-50%);
				margin-top: 10px;
				&::before{
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					position: absolute;
					background: #fff;
					top: -5px;
					.left(50%);
					transform: rotate(45deg);
					z-index: -1; /* stylelint-disable-line declaration-property-value-blacklist */
					.margin-l(-5px);
				}
			}
		}
	}
	.buy-coupon-wrap {
		background-color: #F7F8FA;
		padding: 10px;
		display: flex;
		align-items: center;
		&.disabled{
			.disabled-tips{
				color: #FF411C;
				margin-top: 10px;
			}
			.buy-coupon-checkbox, .title, .desc{
				opacity: .3;
			}
		}
		.buy-coupon-checkbox{
			border-radius: 9999px;
			min-width: 16px;
			&.checked{
				background-color: #222;
				& when (@IS_RW) {
					background-color: @color_brand;
					border: none;
				}
				i{
					border-color: #fff;
				}
			}
			i{
				border-color: #fff;
			}
		}
		.title{
			font-size: 12px;
			color: #222;
			margin-bottom: 5px;
		}
		.desc{
			font-size: 12px;
			color: #666;
		}
		.view-coupons{
			cursor: pointer;
			margin-top: 10px;
			color: #1860A7;
			& when (@IS_RW) {
				color: @color_brand;
			}
			>span{
				position: relative;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	.buycoupon-tips {
		padding: 10px;
		background: #D9EAFF;
		margin-top: 10px;
	}
}
@media (max-width: 1440px) {
	.c-order-summary{
		.check-coupon{
			margin: 0 0 16px 0;
		}
		.check-coupon-bottom {
			margin-bottom: 0;
		}
	}
}
</style>
