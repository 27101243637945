<template>
  <s-drawer
    v-model:visible="showBuyCouponList"
    v-ada-modal="{ show: showBuyCouponList, first: focusTitle }"
    class="buy-coupon-drawer"
    direction="rtl"
    size="430px"
    :append-to-body="true"
    @close="handleClose"
  >
    <template #title>
      <strong ref="buyCouponTitle" tabindex="0">{{
        language.SHEIN_KEY_PC_17350
      }}</strong>
    </template>
    <div class="coupon-content">
      <div class="content-section content-server-rules" tabindex="0">
        <div class="content-title" v-html="language.SHEIN_KEY_PC_17788"></div>
        <div
          class="content-desc"
          v-html="
            htmlDecode({ text: language.SHEIN_KEY_PC_17789, isHtml: true })
          "
        ></div>
      </div>
      <div class="content-section content-coupons" tabindex="0">
        <div class="content-title">
          {{ language.SHEIN_KEY_PC_14930 }}
        </div>
        <div v-show="!buyCouponChecked" class="content-desc">
          <p v-html="buyCouponTitle"></p>
          <p
            v-if="couponDetails && couponDetails.discountPrice"
            v-html="
              template(
                couponDetails.discountPrice.amountWithSymbol,
                language.SHEIN_KEY_PC_17786
              )
            "
          ></p>
        </div>
      </div>
      <ul
        v-show="buyCouponList && buyCouponList.length > 0"
        class="coupon-list"
      >
        <coupon-item
          v-for="(item, index) in buyCouponList"
          :key="`buy-coupon-item-${index}`"
          :item="item"
          :language="language"
          :show-coupon-real-discount="true"
          :expanded="expandedCoupon"
          :is-show-post-amount="isShowPostAmount"
          :showNewTransportationWay="true"
          @triggerExpand="
            (id) => {
              expandedCoupon = id
            }
          "
          @triggerCollapse="expandedCoupon = ''"
        />
      </ul>
      <div class="package-purchase-box">
        <div class="package-purchase-privacy" v-if="showCouponPrivacyItem">
          <s-radio
            v-model="couponPrivacyItem"
            :label="true"
            gap="1rem"
            :toggle="!buyCouponChecked"
          >
            <div class="package-purchase-text">
              {{ language.SHEIN_KEY_PC_21712 }}
              <a
                class="coupon-terms-conditions"
                :href="jumpHref"
                target="_blank"
                >{{ language.SHEIN_KEY_PC_21713 }}</a
              >
            </div>
          </s-radio>
        </div>
        <s-button
          v-if="showBuyCouponBtn"
          class="package-purchase"
          :type="['primary']"
          @click="handlePurchase"
        >
          {{ language.SHEIN_KEY_PC_17367 }}
        </s-button>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { mapState } from 'vuex'
import CouponItem from 'public/src/pages/components/coupon/mall/index.vue'
import { getPostAmountAbt } from 'public/src/pages/components/coupon/mall/utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template as _commonTemplate, htmlDecode } from '@shein/common-function'
import couponMixin from '../../mixins/couponMixin'

const { NODE_SERVER_ENV, langPath } = gbCommonInfo
// import { abtservice } from 'public/src/services/abt'

export default {
  components: {
    CouponItem,
  },
  mixins: [couponMixin],
  data: function () {
    return {
      expandedCoupon: '',
      isShowPostAmount: false,
      couponPrivacyItem: false,
      showCouponPrivacyItem: false,
    }
  },
  computed: {
    ...mapState(['buyCouponChecked', 'buyCouponActivity', 'updatingPrice']),
    showBuyCouponList: {
      get() {
        return this.$store.state.showBuyCouponList
      },
      set(val) {
        this.assignState({ showBuyCouponList: val })
      },
    },
    buyCouponList() {
      const result =
        (this.buyCouponActivity &&
          this.buyCouponActivity.rules &&
          this.buyCouponActivity.rules.coupon) ||
        []
      // 顺序: 最优券 => 可用券 => 不可用券
      result
        .sort((a, b) => b.orderCanUse - a.orderCanUse)
        .sort((a, b) => (b.optimalCoupon || 0) - (a.optimalCoupon || 0))
      return result
    },
    couponsNum() {
      return this.buyCouponList.reduce((num, item) => {
        return num + (Number(item.couponTimes) || 0)
      }, 0)
    },
    buyCouponTitle() {
      const couponPackagePrice =
        this.buyCouponActivity.rules.limitPrice.amountWithSymbol
      return this.template(
        couponPackagePrice,
        this.couponsNum,
        htmlDecode({ text: this.language.SHEIN_KEY_PC_17785, isHtml: true })
      )
    },
    jumpHref() {
      let articleId = ['production', 'gray'].includes(NODE_SERVER_ENV)
        ? '1302'
        : '10113'
      return `${langPath}/Coupon-Policy-a-${articleId}.html`
    },
    showBuyCouponBtn() {
      return !this.buyCouponChecked && !this.buyCouponActivity?.isDisabled
    },
  },
  watch: {
    buyCouponChecked(val) {
      this.couponPrivacyItem = val
    },
  },
  async mounted() {
    this.getCouponPrivacyAbtInfo()
    this.isShowPostAmount = await getPostAmountAbt()
  },
  methods: {
    getCouponPrivacyAbtInfo() {
      // const posKeys = 'BuyTc'
      // const abtInfo = await abtservice.getUserAbtResult({ posKeys })
      if (this.checkout.checkoutBFFAbtInfo?.BuyTc?.param === 'ShowBuyTc') {
        this.showCouponPrivacyItem = true
        return
      }
      this.showCouponPrivacyItem = false
    },
    focusTitle() {
      //drawer的show为false时不渲染html，这里延迟focus ada
      setTimeout(() => {
        this.$refs.buyCouponTitle.focus()
      }, 500)
    },
    template: _commonTemplate,
    htmlDecode: htmlDecode,
    handleClose() {
      daEventCenter.triggerNotice({ daId: '1-11-1-21' })
    },
    handlePurchase() {
      // 计算价格请求过程中 禁止勾选买券，例如选择cod在请求过程中点击买券 页面展示错误
      if (this.updatingPrice) return

      if (!this.couponPrivacyItem && this.showCouponPrivacyItem) {
        this.$message({
          message: this.language.SHEIN_KEY_PC_21714,
          type: 'warning',
        })
        return false
      }
      this.assignState({ showBuyCouponList: false })
      this.$emit('purchase')
      daEventCenter.triggerNotice({ daId: '1-11-1-20' })
    },
  },
  emits: ['purchase'],
}
</script>

<style lang="less" scoped>
.buy-coupon-drawer {
  font {
    color: @sui_color_discount_dark;
  }
	.content-section {
		margin-bottom: 20px;
		.content-title {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 10px;
			color: #222;
		}
		.content-desc {
			color: #666;
			font-size: 14px;
		}
		&.content-coupons {
			margin-top: 30px;
		}
	}
	.package-purchase-box{
		margin-top: 30px;
	}
	.package-purchase-privacy{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.coupon-terms-conditions{
			color: #2d68a8;
			text-decoration: none;
			cursor: pointer;
		}
		.package-purchase-text{
			font-size: 14px;
			display: inline-block;
		}
	}
	.package-purchase {
		margin-top: 10px;
		width: 100%;
		text-transform: uppercase;
	}
}
.coupon-content{
  .coupon-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
    .margin-r(16px);
  }
}
</style>
